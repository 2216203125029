import React from 'react'
import contact from '../assets/texts/contact.png'
import logo from '../assets/texts/logo.png'

const Bio = () => {
  return (
    <div id='text-panel'>
      <img src={logo} alt='Karolina Król logo' width={'75%'} />
      <p>
        The issue might be related to how React updates the UI. While the artwork state is updated correctly in
        handleModeChange, React might not always immediately re-render the component to reflect the change. Here are
        some ways to address this:
      </p>
      <p>Lorem ipsum</p>

      <br />
      <img src={contact} alt='Contact' width={'30%'} />
      <br />
      <span id='links'>
        <a href='facebook.com'>facebook</a>
        <a href='facebook.com'>instagram</a>
      </span>
    </div>
  )
}

export default Bio
