import React, { useState, useEffect } from 'react'
import DraggableImage from './components/DraggableImage'
import './App.css'
import artworkData from './assets/artworkData.json'
import Bio from './components/Bio'

import burger from './assets/burger.png'
import about from './assets/texts/about.png'
import publications from './assets/texts/publications.png'
import illustrations from './assets/texts/illustrations.png'
import posters from './assets/texts/posters.png'
import home from './assets/texts/home.png'

const HomePage = () => {
  const modes = ['home', 'bio', 'posters', 'illustrations', 'publications']
  const [mode, setMode] = useState('home')
  const [artwork, setArtwork] = useState([]) // Filtered artwork based on mode
  const [isBurgerOpen, setBurgerOpen] = useState(false)

  useEffect(() => {
    // Fetch artwork data on initial render
    const fetchData = async () => {
      const response = await fetch(artworkData)
      const data = await response.json()
      setArtwork(data) // Set initial artwork
    }

    if (typeof artworkData === 'string') {
      fetchData()
    } else {
      setArtwork(artworkData)
    }
  }, [])

  const handleModeChange = (newMode) => {
    if (newMode === mode) {
      newMode = 'home'
    }
    setMode(newMode)
    setBurgerOpen(false)
  }

  const toggleBurgerMenu = () => {
    setBurgerOpen(!isBurgerOpen)
  }

  const getModePicture = (mode) => {
    switch (mode) {
      case 'bio':
        return about
      case 'posters':
        return posters
      case 'illustrations':
        return illustrations
      case 'publications':
        return publications
      default:
        return home
    }
  }

  const createNav = () => {
    return (
      <header className='navbar'>
        <div className='burger-menu-container'>
          <div className='burger-button' onClick={toggleBurgerMenu}>
            <img src={burger} alt='burger-menu' width={50} />
          </div>
          {isBurgerOpen && (
            <div className='menu'>
              {modes.map((mode_) => (
                <img
                  key={mode_}
                  className={`${mode_ === mode ? 'nav-selected menu-item' : 'menu-item'}`}
                  onClick={() => handleModeChange(mode_)}
                  src={getModePicture(mode_)}
                  alt={mode_}
                />
              ))}
            </div>
          )}
        </div>
        <nav></nav>
      </header>
    )
  }

  return (
    <div id='home-page'>
      {createNav()}
      {mode === 'bio' && <Bio />}
      <div className='artwork-container'>
        {artwork
          .filter((item) => item.category === mode || mode === 'home')
          .map((image, index) => (
            <DraggableImage key={index} src={image.src} alt={image.alt} description={image.description} />
          ))}
      </div>
    </div>
  )
}

export default HomePage
