// src/components/DraggableImage.js
import React, { useState, useRef } from 'react'
import Draggable from 'react-draggable'
import Modal from './Modal'

const DraggableImage = ({ src, alt, description }) => {
  const [isOpen, setIsOpen] = useState(false)
  const nodeRef = useRef(null)

  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  return (
    <>
      <Draggable
        nodeRef={nodeRef}
        // allowAnyClick={true}
        onStop={handleOpen}
        defaultPosition={{
          x: Math.floor(Math.random() * (window.innerWidth * 0.6)),
          y: Math.floor(Math.random() * (window.innerHeight * 0.6))
        }}>
        <img ref={nodeRef} src={src} alt={alt} className='draggable-image' onClick={handleOpen} />
      </Draggable>
      <Modal isOpen={isOpen} onClose={handleClose} className='modal' description={description}>
        <img src={src} alt={alt} />
      </Modal>
    </>
  )
}

export default DraggableImage
